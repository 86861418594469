@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';
.aside-filter-container {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: $transition;
	height: calc(100vh - 52px);

	@include adapt(992px) {
		width: 0;
		transition: $transition;
	}
}
.aside-filter-copyright {
	padding: 0 16px;
	font-size: 12px;
	color: #c3c3c3;
	text-align: center;
	@include adapt(992px) {
		transform: translateX(-356px);
	}
	@include adapt(480px) {
		display: none;
	}
}
.aside-filter-reestr{
	display: flex;
	gap: 5px;
	font-size: 12px;
	color: #c3c3c3;
	margin-bottom: 20px;
	padding-left: 10px;
}
.is-filter-open {
	.aside-filter-container {
		transition: $transition;
		@include adapt(992px) {
			width: 256px !important;
		}
		@include adapt(480px) {
			width: 0 !important;
		}
	}
	.aside-filter,
	.aside-filter-copyright {
		transform: translateX(0);
		transition: $transition;
		@include adapt(480px) {
			position: absolute;
			width: 272px !important;
			z-index: 10;
		}
	}
	.header-substrate {
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: -10;
		background-color: $blue-dark;
		@include adapt(480px) {
			display: none;
		}
	}
}

.user-projects__container .aside-filter {
	margin-top: 0;
}
.aside-filter {
	position: relative;
	width: 256px;
	margin-top: 24px;
	padding: 0 16px;
	background-color: white;
	transition: $transition;

	@include adapt(992px) {
		transform: translateX(-356px);
	}
	@include adapt(480px) {
		margin-top: 72px;
		z-index: -10;
		border: $border;
		padding: 16px;
	}

	&__form {
		&-head {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 32px;
		}
	}

	&__reset {
		font-size: 13px;
		line-height: 16px;
	}

	&__item {
		margin-bottom: $margin;

		&-head {
			@include flex-between-center;
			cursor: pointer;
		}

		&-select {
			display: flex;
			align-items: center;
			font-size: 13px;
			font-weight: 500;
			font-weight: 700;

			cursor: pointer;
			user-select: none;

			& [class*='-icon'] {
				margin-right: $margin;
			}
		}
		& [class*='-icon'].open {
			transform: rotate(0deg);
			transition: $transition;
		}
	}

	&__select-filter {
		margin-top: 8px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__checkbox {
		margin-top: $margin;
		display: flex;
		align-items: center;
	}
	&__result {
		position: sticky !important;
		display: flex;
		flex-direction: column;
		gap: 12px;
		bottom: 0;
		z-index: 999;

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			background-color: rgba(0, 0, 0, 0.12);
			margin: 16px 0;
		}

		&-btn {
			width: 100%;
		}
	}
}
.aside-filter__input {
	margin-top: 8px;
}
.aside-filter__title {
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	font-weight: 700;
}

.aside-filter {
	.MuiTypography-root {
		font-weight: normal;
		font-size: 13px;
		line-height: 16px;
	}
	.MuiCheckbox-root {
		color: rgba(0, 0, 0, 0.34);
	}
}

.shrt-text-input {
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
	color: rgba(0, 0, 0, 0.54);
}
