.list {
	position: relative;
	padding: 4px;
	background-color: #fff;
	box-shadow: 0px 3px 6px -4px rgba(34, 60, 80, 0.2);
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	width: 224px;
	min-height: 80px;
}

.nothing {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.wrapper {
	position: relative;
}
.dropdown {
	padding: 2px !important;
	position: absolute !important;
	top: 50%;
	right: 6px;
	transform: translate(0, -50%);
	z-index: 100;
}
.rotate {
	transform: translate(0, -50%) rotateX(180deg);
}
.close {
	padding: 2px !important;
	position: absolute !important;
	top: 50%;
	right: 24px;
	transform: translate(0, -50%);
	z-index: 100;
}
