@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';
.common-projects__rates {
	width: 100%;

	&-head {
		@include flex-between-center;
		width: 100%;
		.title {
			font-weight: normal;
			font-size: 18px;
			line-height: 24px;
		}
		button {
			margin-left: 5px;
		}
		.MuiIconButton-label {
			width: 30px;
			height: 30px;
		}
		.MuiIconButton-root {
			padding: 0;
		}
	}

	&-actions {
		display: flex;
		align-items: center;
		height: 100%;

		.rates-actions {
			&__total {
				font-weight: normal;
				font-size: 16px;
				line-height: 24px;
				margin-right: 20px;
				@include flex-between-center;
				@include adapt(480px) {
					display: none;
				}
			}

			&__filter {
				@include flex-between-center;

				& > * {
					margin-right: 10px;
				}
			}

			&__toggle {
				margin: 0 8px 0 32px;
			}
		}
	}
}

.rates-filter {
	.MuiInputLabel-formControl {
		transform: translate(0, -20px) scale(0.75) !important;
		font-size: 12px !important;
		color: $black !important;
	}
	fieldset {
		legend {
			display: none !important;
		}
	}

	.PrivateNotchedOutline-root-192 {
		border-radius: 4px !important;
	}
	.MuiTypography-root {
		font-weight: normal;
		font-size: 13px;
		line-height: 16px;
	}
	.MuiCheckbox-root {
		color: rgba(0, 0, 0, 0.34) !important;
	}
}
// .rates__table {
// 	margin: 0 auto;
// 	margin-top: 12px;
// 	padding: 16px 16px;
// 	width: 1280px;
// 	@include adapt(1280px) {
// 		width: 100%;
// 	}
// 	@include adapt(1100px) {
// 		width: 1130px;
// 	}
// }
