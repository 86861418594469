.assist-main {
	margin-top: 20px;
}
.select-input {
	width: 100%;
	margin-top: 10px;
	border: 0.5px solid gray;
	border-radius: 2px;
}

.MuiSelect-select:focus {
	border-radius: 0;
	background: none !important;
}

.select_arrow_icon {
	transition: all 1.3s ease 3s;
}

.active {
	//   transform: rotate(180deg);
}
.MuiSelect-icon {
	display: none !important;
}
.assist-required-block {
	display: flex;
	gap: 20px;
	cursor: pointer;
}
.assist-block {
	margin: 20px 0 0 0;
}
.assist-block:last-child {
	color: red;
	.assist-block-subtitle {
		font-size: 14px;
		margin-top: 10px;
	}
}
.assist-block_header {
	display: flex;
	cursor: pointer;
}
.assist-block_uncs {
	position: relative;
	max-height: 130px;
	overflow-y: auto;
    padding-right: 5px;
}
.assist-block_uncs::-webkit-scrollbar {
	width: 5px;
}
.assist-block_uncs::-webkit-scrollbar-thumb {
	background-color: #a7a6a6;
	border-radius: 5px;
	position: absolute;
	background-clip: padding-box;
}
.assist-block_uncs::-webkit-scrollbar-track {
	background: #ececec;
    border-radius: 5px;
}
.MuiSelect-select {
	margin-left: 10px !important;
}

.svg-rotate {
	transition: transform 0.5s ease-in-out;
	&.turn {
		transform: rotate(180deg);
	}
}

.required {
	.MuiSelect-select {
		color: green !important;
	}
}
.possible {
	.MuiSelect-select {
		color: orange !important;
	}
}
.required-empty {
	color: green;
}
.dissabled {
	&.MuiMenuItem-root {
		color: gray !important;
	}
}
