@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';
.header {
	position: relative;
	background-color: $blue-dark;
	width: 100%;
	height: 64px;
}
.container {
	height: 100%;
}
.headerContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 100%;
}
.container,
.nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.demoSign {
	text-align: center;
	padding: 10px;
}
.demoSign > Img {
	display: inline-block;
	vertical-align: top;
}
.demoSign > Span {
	display: inline-block;
	font-size: 13px;
	font-weight: 500;
	font-weight: 700;
	color: #fff;
	vertical-align: -webkit-baseline-middle;
	margin-left: 8px;
}
