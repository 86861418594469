@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';
.card-container {
	transition: $transition;
	display: flex;
	flex-wrap: wrap;
	border: $border;
	border-radius: 4px;
	margin-bottom: 16px;
	height: 100%;
	.flex {
		display: flex;
		align-items: center;
	}
	.icon-wrapper {
		margin-left: 12px;
		cursor: pointer;
		opacity: 0;
		svg {
			color: #3f4f5e;
			&:hover {
				color: #1baaf0;
			}
		}
	}
	.icon-wrapper-favorite {
		cursor: pointer;
		margin-left: 12px;
		opacity: 1;
	}
	.icon-wrapper_active {
		margin-left: 12px;
		svg {
			color: #3f4f5e;
			&:hover {
				color: #1baaf0;
			}
		}
	}
	.link-icon {
		width: 16px;
		height: 16px;
	}
	&:hover {
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

		.card-container__actions {
			color: #1baaf0;
			opacity: 1;
		}
		.icon-wrapper {
			opacity: 1;
		}
	}
	@include adapt(480px) {
		border: none;
		border-top: $border;
		border-radius: 0;
	}
	.card-container__description {
		-webkit-line-clamp: 3;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}
.card-container__actions {
	opacity: 0;
}

.card-container__left {
	width: calc(100% - 320px);
	padding: 16px;
	@include adapt(768px) {
		width: 100%;
	}
}
.card-container__right {
	width: 320px;
	padding: 16px;

	@include adapt(768px) {
		width: 100%;
	}
}
.card-container__code {
	font-size: 18px;
	line-height: 24px;
	display: flex;
	align-items: center;
	position: relative;

	@include adapt(480px) {
		font-size: 16px;
		padding-left: 22apx;
	}
	.code {
		margin-left: 4px;
		cursor: pointer;

		max-width: 165px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		flex-grow: 1;
	}
}
.card-container__title {
	line-height: 24px;
	margin-top: $margin;
}
.card-container__detailed-btn {
	font-size: 13px;
	padding: 0;
	color: $blue-dark;
	@include adapt(768px) {
		display: none;
	}
	&:hover {
		color: #1baaf0;
	}
}
.card-container__wrapper-actions {
	display: flex;
	align-items: center;
	width: 100%;
	margin-left: 5px;
}
.card-container__detailed-btn {
	cursor: pointer;
}
.card-container__head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 27px;
	overflow: hidden;
}
.card-container__date {
	font-size: 14px;
	margin-top: $margin;
	color: $grey;
}

.card-container__right__item {
	margin-bottom: $margin;
	&:last-child {
		margin-bottom: 0;
	}
}
.card-container__right__item-value,
.card-container__detailed-info__item-value {
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	min-height: 32px;
	padding: 8px;
	margin-top: 4px;
	cursor: default;
	background-color: white;
	span {
		font-size: 12px;
		line-height: 16px;
		color: rgba(0, 0, 0, 0.34);
	}
}

.card-container__detailed {
	display: flex;
	background-color: rgba(0, 0, 0, 0.03);
	@include adapt(768px) {
		display: none;
	}
}
.MuiCollapse-container {
	width: 100%;
}
.card-container__detailed--column {
	flex-direction: column;
}
.card-container__right__item-title,
.card-container__detailed-info__item-title {
	font-size: 12px;
	line-height: 16px;
	color: rgba(0, 0, 0, 0.87);
}

.space-between {
	justify-content: space-between;
}
.flex-end {
	justify-content: flex-end;
}

.card-container__detailed-info {
	padding: 16px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	background-color: rgba(0, 0, 0, 0.03);
}
.common-projects-detailed-width {
	width: calc(100% - 240px);
	@include adapt(768px) {
		width: 100%;
		order: 2;
	}
	.card-container__detailed-info__item {
		width: 48%;
	}
}
.purchases-detailed-width {
	width: 100%;
	.card-container__detailed-info__item {
		width: 33%;
		@include adapt(1280px) {
			width: 48%;
		}
	}
}
.card-container__detailed-price {
	width: 240px;
	padding: 16px;
	@include adapt(768px) {
		width: 100%;
		order: 1;
	}
}

.card-container__detailed-info__item-title {
	height: 32px;
	display: flex;
	align-items: flex-end;
}
.card-container__comment {
	margin-top: $margin;
}
.card-container__comment-value {
	position: relative;
	font-size: 14px;
	line-height: 16px;
	padding: 8px;
	border-radius: 4px;
	margin-top: 4px;
	-webkit-line-clamp: 3;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;

	&.show-border {
		border: $border;
	}
	&:after {
		content: '';
		display: block;
		height: 8px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #fff;
	}
}
.card-container__comment-shrt-txt {
	font-size: 12px;
	line-height: 16px;
}

.cost-works-icon {
	width: 16px;
	height: 24px;
}

.linkIcon {
	display: block;
	color: #3f4f5e;
	width: 16px;
	margin-left: 8px;
	cursor: pointer;
}
.linkIcon:hover {
	color: #1baaf0;
}

.cardUserProjects {
	font-family: 'Manrope', sans-serif;

	.card-container__left {
		position: relative;
		padding: 24px;
		max-width: 310px;
		border-right: $border;
		width: 33%;
		@include adapt(768px) {
			width: 100%;
		}
	}
	.card-container__right {
		width: 100%;
		padding: 24px;

		@include adapt(768px) {
			width: 100%;
		}
	}

	.card-container {
		&__code .code {
			margin-left: 0;
			font-weight: 800;
		}
		&__regions {
			display: flex;
			flex-direction: column;
			margin-top: 16px;
			font-size: 14px;
			font-weight: 500;
			gap: 8px;
		}
		&__periods {
			margin-top: 44px;
			max-width: 275px;

			&Title {
				font-weight: 800;
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 16px;
			}
			&-range {
				position: relative;
				max-width: 100%;
				width: 100%;
				height: 2px;
				background: #d7d7d7;
				margin: 2px;
				margin-bottom: 10px;
				&.cardRight {
					margin-top: 16px;
					margin-bottom: 12px;
					background: transparent;
				}
			}

			&-fill {
				height: 100%;
				background: #292a34;
				border-bottom-right-radius: 0px;
				width: 0;
				&.cardRight {
					background: #09bcf6;
					border-radius: 2px;
				}
			}
			&-fill--reverse {
				position: absolute;
				top: 0;
				right: 0;
				background-color: #d33131;
				border-radius: 2px;
			}

			&-dot {
				position: absolute;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: #292a34;
				left: 0;
				z-index: 100;
				top: 50%;
				transform: translateY(-50%);

				&::before {
					content: '';
					width: 4px;
					height: 4px;
					background-color: #fff;
					border-radius: 50%;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					position: absolute;
				}
			}
			&-dot--right {
				right: 0;
				left: auto;
				background: #d7d7d7;
			}
		}
		&__periodSubTitles {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: 500;
		}
		&__period {
			font-size: 14px;
		}
		&__company-name {
			font-size: 18px;
			font-weight: 800;
			margin-bottom: 8px;
		}

		&__techConnect {
			font-size: 14px;
			font-weight: 700;

			margin-bottom: 12px;
		}

		&__description {
			p {
				font-size: 14px;
				font-weight: 500;
				line-height: 20px;
				text-overflow: ellipsis;
				display: -moz-box;
				-moz-box-orient: vertical;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				box-orient: vertical;
				transition: 0.3s;
			}
		}
		&__description-btn {
			display: flex;
			align-items: center;
			gap: 4px;
			font-size: 14px;

			&:hover .card-container__description-btnText {
				text-decoration-style: solid;
				transition: 0.3s;
			}
		}
		&__description-btnText {
			text-decoration: underline;
			text-underline-offset: 4px;
			text-decoration-style: dotted;
			transition: 0.3s;
			cursor: pointer;
		}

		&__amountOfFunding {
			margin-top: 30px;
		}

		&__amountOfFunding-grid {
			max-width: 500px;
			width: 100%;
		}

		&__totalInvestmentUncOffer {
		}
		&__totalInvestmentUncOffer-title {
			font-size: 18px;
			font-weight: 800;
		}
		&__totalInvestmentUncOffer-unc {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #f1f1f1;
			width: 42px;
			height: 24px;
			border-radius: 6px;
			text-transform: uppercase;
			font-size: 12px;
			color: #898a8f;
			padding: 4px 8px;
		}
		&__totalInvestmentUncOffer-value,
		&__additionalFundingOffer-value {
			display: flex;
			justify-content: flex-start;
			font-size: 16px;
		}

		&__additionalFundingOffer-title {
			font-size: 18px;
			font-weight: 600;
		}
		&__valuationOfferForecast,
		&__limit {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
		&__valuationOfferForecast {
			max-width: 250px;
			width: 100%;
		}
		&__valuationOfferForecast-value,
		&__limit-value {
			white-space: nowrap;
			font-weight: 700;
			line-height: 20px;
			font-size: 14px;
		}
		&__valuationOfferForecast-title,
		&__limit-title {
			font-weight: 500;
			line-height: 14px;
			font-size: 12px;
			color: #898a8f;
		}
	}
}
