.adminPanel-container {
	margin-top: 30px;
	margin-bottom: 30px;
}

.adminPanelCell {
	min-width: 0;
	overflow-x: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	&__disabled {
		color: red !important;
	}
}

.adminPanel__tableRow {
	&:hover {
		background-color: #eee;
	}
}

.adminUpdate__accessCheckbox {
	display: flex;
	align-items: center;
	gap: 8px;
}

.adminUpdate__datepicker {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.adminPanel-container {
	// margin-top: 30px;
	max-width: 1280px;
	margin: 0 auto;
	// max-width: calc(1280px - 256px);
	position: relative;
}
