.modal {
	&Wrap {
		max-width: 400px;
		padding: 20px;
	}

	&Title {
		display: block;
		font-weight: 700;
		margin-bottom: 12px;
	}

	&Footer {
		display: flex;
		gap: 12px;
		margin-top: 12px;
	}
}
