.loader {
	width: 94px;
	height: 94px;
	background-image: url('/assets/images/svg/loader.svg');
	background-repeat: no-repeat;
	background-size: contain;
	margin: 70px auto;
	animation: loading 1.5s infinite linear;

	&__btn_menu {
		width: 20px;
		height: 20px;
		background-image: url('/assets/images/svg/loader.svg');
		background-repeat: no-repeat;
		background-size: contain;
		margin-right: 5px;
		animation: loading 1.5s infinite linear;
		pointer-events: none !important;
	}
}

@keyframes loading {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}
