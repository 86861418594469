@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';
.container {
	// overflow: auto;
	margin: 0 auto;
	padding: 16px 16px;
	width: 1280px;

	&::-webkit-scrollbar {
		width: 4px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: rgba(148, 147, 147, 0.34);
		border: 2px solid transparent;
		border-radius: 8px;
		background-clip: content-box;
	}
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 2px rgba(69, 69, 69, 0.455);
		background-color: $table-bg;
		border-radius: 8px;
		background-clip: content-box;
		width: 1px;
	}
}
.table {
	margin: 0 auto;
	margin-top: 12px;
	width: 100%;
}
.grid {
	display: grid;
}
.alignTheElement {
	align-items: center;
	height: 100%;
}
.thead {
	align-items: center;
	min-height: 32px;
	padding: 8px;
	background-color: $table-bg;
	font-size: 12px;
	line-height: 16px;
	color: $light-grey;
}
.theadCellWrap {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}
.theadCell {
	display: flex;
	align-items: flex-start;
	height: 100%;
}
.textAlignLeft {
	text-align: left;
}
.textAlignRight {
	text-align: right;
}
.textAlignCenter {
	text-align: center;
}

.row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 32px;
	font-size: 14px;
	line-height: 24px;
	position: relative;
}
.activeRow {
	background: $table-bg;
}

.rowWrapper {
	border-collapse: collapse;
	height: 32px;
	position: relative;
	&:focus {
		outline: none;
		border: none;
	}
}

.uncCounter {
	display: block;
	width: 100%;
	height: 24px;
	text-align: center;
	border: $border;
	border-radius: 4px;
	padding-right: 4px;
	&::placeholder {
		color: $light-grey !important;
	}
}
.accent {
	color: $main-blue;
}

.regionName {
	font-size: 12px;
}
.rowTitle {
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 14px;
	line-height: 16px;
	padding-left: 24px;
	color: $grey;
}
.TitleWrapper {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.open {
	transform: rotate(180deg);
}
.total {
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
}
.rowItem {
	position: relative;
	max-width: 1248px;
	width: 100% !important;
	height: 32px !important;
	border: $border;
	border-top: none;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	align-items: center;
	&:first-child {
		border-top: $border;
	}
}
.item {
	margin-top: 16px;
}
.arrow {
	margin-right: 8px;
}
.tableCell {
	position: relative;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	padding: 0 8px 15px 0;
	max-width: 200px;
	-webkit-line-clamp: 1;
	white-space: nowrap;
	-webkit-box-orient: vertical;
}
.tableCellWarning {
	position: absolute;
	right: 0;
	top: -2px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	padding: 0 8px 15px 0;
	max-width: 50px;
	-webkit-line-clamp: 1;
	white-space: nowrap;
	-webkit-box-orient: vertical;
	cursor: pointer !important;
}
.nothing {
	display: flex;
	align-items: center;
	font-size: 14px;
	line-height: 16px;
	color: $light-grey;
	height: 32px;
	line-height: 50px;
	border: $border;
	padding-left: 40px;
}
:global() {
	.rowWrapper {
		&:hover {
			.buttonGroup {
				opacity: 1;
			}
			.buttonGroupLeft {
				opacity: 1;
			}
		}
	}
}
.buttonGroup {
	display: flex;
	gap: 8px;
	opacity: 0;
	position: absolute;
	right: -60px;
	top: 2px;

	&.isNoActualUncSort {
		right: -28px;
	}

	&.isNoActualUncSort .iconUncReplace {
		display: none !important;
	}
}
.iconWrap {
	background-color: #eee !important;
	width: 24px;
	height: 24px;
}
.icon {
	transform: scale(0.8);
}

.buttonGroupLeft {
	display: flex;
	gap: 8px;
	opacity: 0;
	position: absolute;
	left: -64px;
	top: 2px;

	&.onlyUp,
	&.onlyDown {
		left: -32px;
	}

	&.onlyUp .swapDownBtn {
		display: none !important;
	}
	&.onlyDown .swapUpBtn {
		display: none !important;
	}
}

.swapIcon {
	cursor: pointer;
	z-index: 2;
	@include adapt(1280px) {
		right: -99%;
	}
}

.delBtn {
	cursor: pointer;
	z-index: 2;
	@include adapt(1280px) {
		right: -99%;
	}
}

.rowWrapperNonRelevant {
	border: 1px solid red;
	background-color: rgba(255, 0, 0, 0.1);
	&:first-child {
		border: 1px solid red;
	}
}

.draggingOver {
	border: 1px solid #e0e0e0;
	width: 200px !important;
	background: white;
	height: 32px !important;
	align-items: center;
	overflow: hidden;
	border-radius: 4px;
	padding: 0;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
}

.container_body {
	position: relative;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	align-items: center;
	justify-content: center;
	z-index: 999;
}
