@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.parameters__card-container__right-item {
	margin-top: 10px;
	&:first-child {
		margin-top: 0;
	}
}

.parameters__detailed-item {
	.title {
		font-size: 12px;
		line-height: 16px;
	}
	.value {
		border: 1px solid #e0e0e0;
		height: 32px;
		margin-top: 5px;
		display: flex;
		align-items: center;
		padding-left: 8px;
		font-size: 14px;
		line-height: 16px;
		display: flex;
		justify-content: space-between;
		span {
			margin-right: 5px;
			font-size: 12px;
			line-height: 16px;
			color: $light-grey;
		}
	}
}

.parameters__detailed-head {
	display: flex;
	@include flex-between-center;
}
.parameters__detailed {
	margin: 32px 0;
}
.parameters__detailed-select {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	@include adapt(768px) {
		max-width: 150px;
	}
}

// убирает нижнее подчеркивание
.MuiInput-underline {
	&:before {
		display: none !important;
	}
}
// убирает нижнее подчеркивание
.MuiInput-underline:after {
	display: none !important;
}
// показывает svg иконку
.MuiSvgIcon-root {
	overflow: visible !important;
}

.parameters__detailed-info {
	font-size: 14px;
	margin-right: 16px;
	border-radius: 4px !important;
	cursor: pointer;

	&.active {
		color: $main-blue;
		@include adapt(768px) {
			background: rgba(27, 170, 240, 0.1) !important;
		}
	}
	@include adapt(768px) {
		margin-right: 0;
		color: rgba(0, 0, 0, 0.87);
		display: block;
		min-height: 32px !important;
	}
}
.parameters__detailed-data {
	margin-top: 16px;
	display: flex;
	flex-wrap: wrap;
	column-gap: 24px;
	row-gap: 8px;
	@include adapt(1200px) {
		column-gap: 12px;
	}
	@include adapt(480px) {
		column-gap: 0;
	}
}
.desktop-screen {
	@include adapt(768px) {
		display: none;
	}
}
.mobile-screen {
	display: none !important;
	@include adapt(768px) {
		display: block !important;
	}
	.ArrowDownSVG {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		path {
			stroke: $black !important;
		}
	}
}
.parameters__detailed-item {
	width: calc(100% / 4);
	@include adapt(1200px) {
		width: calc(25%);
	}
	@include adapt(1100px) {
		width: calc(100% / 3);
	}
	@include adapt(992px) {
		width: calc(100% / 3 - 12px);
	}
	@include adapt(768px) {
		width: calc(100% / 2 - 12px);
	}
	@include adapt(480px) {
		width: 100%;
	}
	.title {
		height: 32px;
		display: flex;
		align-items: flex-end;
	}
}
.value-data-left,
.value-data-right {
	display: inline-block;
	width: 50%;
	height: 100%;
	display: flex;
	align-items: center;
}
.value-data-right {
	border-left: 1px solid #e0e0e0;
	padding-left: 8px;
}
