.voltage {
	position: relative;
	padding-right: 20px;
	width: 100%;
	margin: 6px 0;
	cursor: pointer;
	&Select::before {
		content: '';
		position: absolute;
		width: 11px;
		height: 7px;
		background-image: url('/assets/images/svg/dropdown.svg');
		background-repeat: no-repeat;
		right: 0;
		top: calc(50% - 4.5px);
	}
}
.wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
