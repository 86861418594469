@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.navbar {
	display: flex;
	gap: 4px;
}

.navLink {
	display: inline;
	margin-right: 16px;
	font-size: 15px;
	line-height: 16px;
	color: #fff;
	transition: $transition;
	cursor: pointer;
	position: relative;
	border-radius: 4px !important;

	&:hover {
		color: $main-blue;
	}
	@include adapt(425px) {
		margin-right: 8px;
	}
}

.active {
	color: $main-blue !important;
	background-color: rgba(0, 0, 0, 0.04) !important;
}

.tabs {
	margin: 12px 0;
}

.tab {
	background-color: $main-blue !important;
	color: #fff !important;
	border-radius: 8px !important;
}

.input_modal_company input {
	&:hover {
		border: 1px solid red !important;
	}
}

.adminPanel__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 12px 0;

	&Left {
		display: flex;
		align-items: center;
		gap: 8px;
	}
}
.boldText {
	font-weight: 700;
}
