@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.navLink {
	display: inline;
	margin-right: 16px;
	font-size: 15px;
	line-height: 16px;
	color: #fff;
	transition: $transition;
	cursor: pointer;
	position: relative;

	&:hover {
		color: $main-blue;
	}
	@include adapt(425px) {
		margin-right: 8px;
	}
}
.active {
	color: $main-blue;
}

.linkWrapper {
	position: relative;
	display: inline-block;
	width: 45px;
  }
  

.verifiedIcon {
	position: absolute;
	top: 50%;
	left: 90%;
	transform: translate(-50%, -50%);
	margin-left: 4px;
	color: $main-blue;
  }