@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.wrapper-input {
	position: relative;
}
.universal-label {
	display: block;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 4px;
}
.part-width {
	width: 49%;
	display: flex;
	justify-content: space-between;
	.place-input {
		position: absolute;
		left: 4px;
		top: 50%;
		transform: translateY(-50%);
	}
	.universal-input {
		padding-left: 24px !important;
		padding-right: 8px;
		text-align: right;
	}
}
.full-width {
	width: 100%;
	.place-input {
		position: absolute;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 7;
	}
	.universal-input {
		padding-left: 8px !important;
		padding-right: 36px;
	}
}
.universal-input {
	border: $border;
	border-radius: 4px;
	width: 100%;
	height: 32px;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	outline: none;
	&:hover {
		border: 1px solid $black;
	}
	&:hover:disabled {
		border: $border;
	}
	&:focus {
		padding-left: 7px;
		border: 1px solid $main-blue !important;
	}
}

.reset-icon,
.props-icon {
	padding: 4px !important;
}
.massage-error {
	margin-top: 4px;
	color: $error;
	font-size: 12px;
}
.massage {
	margin-top: 4px;
	font-size: 12px;
}
