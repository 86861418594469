@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.voltage_select_item {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 100%;
	cursor: pointer;
	&-active {
		color: $main-blue;
	}
}
