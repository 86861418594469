@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.help-window {
	background-color: white;
	border: 1px solid #ddd;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	max-width: 1000px;
	padding: 10px;
	font-size: 14px;
	width: auto;
	padding: 10px;
  }
  
  .help-window-content {
	max-height: 650px;
	overflow-y: auto;
	h3{
		text-align: center;
		font-weight: bold;
	}
	h4 {
		font-weight: 600;
		margin-top: 20px;
	}
	p {
		white-space: pre-wrap;
	}
  }
  .tooltip_video_content{
	
  }

  .add_btn_tooltip_import {
	padding: 4px 8px;
	border-radius: 8px;
	svg {
		width: 20px;
		height: 20px;
		margin-right: 4px;
		stroke: #fff;
	}
	&:active {
		svg {
			stroke: #fff;
		}
	}
	display: inline;
	margin-right: 0.4em;
	width: 400px;
}
.import_tooltip_buttons{
	display: grid;
	gap: 10px;
	justify-content: center;
}

.divider {
	margin: 10px 0;
	border: none;
	border-top: 1px solid #ccc;
  }
  
  .centered-video {
	display: block;
	margin: 10px auto;
  }