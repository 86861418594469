$main-blue: #1baaf0;
$black: rgba(0, 0, 0, 0.87);
$light-grey: rgba(0, 0, 0, 0.45);
$grey: rgba(0, 0, 0, 0.56);
$border: 1px solid rgba(0, 0, 0, 0.12);
$margin: 8px;
$transition: all 0.15s ease-in-out;
$green: #71dc6f;
@mixin flex-between {
	display: flex;
	justify-content: space-between;
}

@mixin text-overflow-hidden {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

@mixin flex-between-center {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin flex-column-center {
	display: flex;
	flex-direction: column;
	align-items: center;
}

@mixin circle_btn {
	min-width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid $main-blue;
	background-color: transparent;
	background-position: center center;
	background-repeat: no-repeat;
	cursor: pointer;
	transition: background-color 0.2s ease;
	&:hover {
		background-color: #38818817;
	}
}
@mixin input {
	height: 40px;
	background: #ffffff;
	border: $border;
	border-radius: 6px;
	color: $black;
	font-size: 14px;
	padding: 0 10px;
	overflow: hidden;
	text-overflow: ellipsis;
	outline: none;
}
@mixin adapt($num) {
	@media screen and (max-width: $num) {
		@content;
	}
}
