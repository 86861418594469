.nav-submenu {
  z-index: 5;
  width: 100%;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 200%;
  left: 0;
  padding: .5rem;
  background-color: #3f4f5e;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  transition: all 200ms ease;
}

.nav-submenu.nav-submenu--active {
  visibility: visible;
  opacity: 1;
}

.nav-submenu a {
  margin: 0;
}
