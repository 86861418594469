@import "@root-gipro/styles/settings";

.project-list {
  display: contents;
  width: 100%;

  &__head {
    margin: 24px 0;
    line-height: 24px;
    @include flex-between;
    flex-wrap: wrap;

    &-info {
      @include flex-between;
    }

    &-toggle {
      @include flex-between-center;
      margin-right: 24px;

      &-item {
        cursor: pointer;
        transition: $transition;

        &.active {
          color: $main-blue;
        }
      }

      div:first-child {
        margin-right: 16px;
      }
    }

    &-result {
      margin-right: 16px;
      font-size: 18px;

      span {
        color: $grey;
        font-size: 16px;
      }
    }

    &-sum {
      font-size: 18px;
    }
  }

  &__sort {
    &-head {
      @include flex-between-center;
      margin: 24px 24px 32px 16px;
      outline: none;
    }

    &-select {
      text-transform: uppercase;
      color: $main-blue;
      cursor: pointer;
      @include flex-between-center;

      & > div {
        @include flex-between-center;
      }

      &-option {
        margin: 0 10px;
      }
    }

    &-actions {
      @include flex-between-center;

      .order {
        margin: 0 16px;
        color: $light-grey;
      }
    }
  }
}
