@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';
.breadcrumbs-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 48px;
	margin-bottom: 32px;
	.breadcrumbs-navlink,
	.MuiTypography-root {
		color: $light-grey;
	}
	.breadcrumbs-navlink {
		cursor: pointer;
	}
	.breadcrumbs-navlink:hover {
		text-decoration: underline;
	}
}

.breadcrumbs-right-icon,
.breadcrumbs__shrt-txt {
	@include adapt(480px) {
		display: none !important;
	}
}
.breadcrumbs__shrt-txt {
	max-width: 200px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.breadcrumbs-left-icon {
	display: none !important;

	@include adapt(480px) {
		display: inline-block !important;
		margin-right: 5px;
	}
}
