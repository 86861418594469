@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.project_nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	line-height: 24px;
	max-width: 1380px;
	width: 100%;
	.project_code {
		display: flex;
		align-items: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 16px;
		[class*='icon'] {
			margin-right: 15px;
		}
	}
	.project_name {
		width: 535px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		color: rgba(0, 0, 0, 0.3);
		font-size: 20px;
		margin-left: 20px;
		margin-right: 47px;
	}
	a {
		text-decoration: none;
		position: relative;
		font-size: 20px;
		color: $main-blue;
		img {
			width: 8px;
			height: 12px;
			margin-right: 10px;
		}
	}
	.hide_btn {
		color: $main-blue;
		font-size: 14px;
		padding-right: 22px;
		position: relative;
		margin-right: 37px;
		cursor: pointer;
		width: 85px;
		text-align: right;
		&::before {
			content: '';
			width: 12px;
			height: 7px;
			position: absolute;
			margin-right: -10px;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			background-image: url('/assets/images/Vector_119.png');
		}
	}
	.copy_btn {
		margin-right: 20px;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background-color: $main-blue;
		cursor: pointer;
		background-image: url('/assets/images/copied.png');
		background-position: center;
		background-repeat: no-repeat;
		opacity: 0.3;
		transition: opacity 0.3s ease;
		&.copied {
			opacity: 1;
		}
		&:hover {
			opacity: 1;
		}
	}
}
.total_s1 {
	display: flex;
	position: relative;
	justify-content: flex-end;
	margin-top: 16px;
}
.activeTotal {
	font-size: 14px;
	&__tables {
		margin-left: 24px;
		margin-bottom: 32px;
	}
	&__label {
		display: block;
		margin: 8px 0;
	}
	&__input {
		width: 100%;
		margin-bottom: 8px;
		@include input;
		&:disabled {
			outline: none;
			filter: brightness(95%);
		}
	}
}

.table_label {
	margin-top: 8px;
	&:first-child {
		margin-top: 0;
	}
	input {
		display: none;
	}
	label {
		cursor: pointer;
		color: $black;
		font-size: 12px;
		position: relative;
		&::before {
			content: '';
			position: absolute;
			width: 16px;
			height: 16px;
			border: $border;
			top: calc(50% - 7px);
			left: -24px;
			border-radius: 50%;
		}
		&::after {
			content: '';
			position: absolute;
			width: 10px;
			height: 10px;
			background: #fff;
			top: calc(50% - 5px);
			border-radius: 50%;
		}
	}
	input:checked + label {
		&::before {
			border: 5px solid $blue-dark;
		}
	}
}

.scrollarea {
	.scrollbar-container {
		&:hover {
			background: transparent;
		}
	}
	.scrollbar {
		border-radius: 18px;
		background: #e0e0e0 !important;
	}
}
.unc_table__head {
	display: flex;
	justify-content: space-between;
}
.head-left__title {
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
}
