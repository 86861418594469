@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.admin-container {
	margin: 0 auto;
	max-width: 1280px;
	gap: 1.5rem;
	padding: 1rem 0 0;

	@include adapt(375px) {
		min-width: 375px;
		overflow: hidden;
	}
}
.admin-table__icon {
	cursor: pointer;
	background-color: #3f4f5e;
	border-radius: 0.25rem;
	padding: 0.3rem 1.2rem;
}

.admin-table__icon svg {
	height: 15px;
	fill: white;
}

.admin-form__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 1rem;
	border-bottom: 1px solid #cecece;
	margin-bottom: 1rem;
}

.admin-form__header-buttons {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.admin-input__postfix-container {
	position: relative;
}

.admin-input__postfix {
	position: absolute;
	right: 0.5rem;
	top: 50%;
	transform: translateY(-50%);
	font-size: 0.8rem;
}

.admin-fieldset {
	border: none;

	&--cols-3 {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 1rem;
	}
	&--cols-2 {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem;
	}
}

.admin-label {
	position: relative;
}

.admin-label p {
	font-size: 0.9rem;
	margin-bottom: 0.2rem;
}

.admin-input {
	font-size: 0.85rem;
	width: 100%;
	padding: 0.3rem;
	border-radius: 0.25rem;
	border: 1px solid #b6b6b6;

	&.admin-input--error {
		border-color: #c42a41;
	}

	&__error {
		font-size: 0.85rem;
		color: #c42a41;
	}

	&::placeholder {
		color: #b6b6b6;
	}

	&--postfix {
		padding-right: 3rem;
	}
}

.admin-button {
	padding: 0.5rem 1.2rem;
	border-radius: 0.3rem;
	font-size: 0.9rem;
	cursor: pointer;
}

.admin-button.primary {
	background-color: #2a59c4;
	color: white;
}

.admin-button.danger {
	background-color: #c42a41;
	color: white;
}

.btn-spinner {
	max-height: 24px;
	max-height: 74px;
	position: absolute;
}
.admin-upload {
	&-wrap {
		display: flex;
		align-items: center;
		gap: 10px;
		max-width: 390px;
	}
	&-btn {
		height: 30px;
		flex-basis: 200px;
	}
	&-filename {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		flex-grow: 1;
	}
}
