@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.region_zones {
	transition: max-height 0.3s ease-in-out;
	overflow: hidden;
	padding-left: 20px;

	.drop-down_label::before {
		width: 14px;
		height: 14px;
		top: 2px;
	}

	input:checked + .drop-down_label {
		&::before {
			border: 4.7px solid $blue-dark;
		}
	}
}

.items_container {
	max-height: 1000px;
	transition: max-height 5s ease-in-out;
}

.title_zone{
	padding-left: 10px;
	margin: 10px 0;
	font-size: 20px;
	font-weight: 600;
}

.title_region {
	margin-bottom: 20px;
	transition: max-height 0.3s ease-in-out;
	overflow: hidden;
}
