@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';
.modal {
	background-color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	&:focus {
		outline: none;
		border: none;
	}
}
.container {
	padding: 16px;
}
.blockTitle {
	margin-bottom: 16px;
	display: flex;
	justify-content: space-between;
}
.leftTitle {
	font-weight: normal;
	font-size: 18px;
	line-height: 24px;
}

.rightTitleGray {
	font-size: 13px;
	line-height: 16px;
	cursor: pointer;
	color: $grey;
	&:hover {
		text-decoration: underline;
	}
}
.rightTitleBlue {
	font-size: 13px;
	line-height: 16px;
	cursor: pointer;
	color: $main-blue;
	&:hover {
		text-decoration: underline;
	}
}
.body {
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	margin-bottom: 16px;
}
.flex {
	display: flex;
}
.justifyCenter {
	justify-content: center;
}
.spaceBetween {
	justify-content: space-between;
}
.svg {
	fill: none;
	width: 14px;
	height: 14px;
	fill: transparent;

	path {
		fill: $blue-dark;
	}
}
