@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';
@import './Select.scss';
.check-plate {
	// padding-top: 16px;
	.MuiOutlinedInput-input {
		color: $black;
		font-size: 14px;
		padding: 8px;
		&::placeholder {
			color: $grey;
			font-size: 14px;
		}
	}

	.code {
		padding: 0 8px;
		height: 32px;
		border: 1px solid #e0e0e0;
		border-radius: 4px !important;
	}
	.groupNum {
		font-size: 13px;
		padding: 0 8px;
		height: 32px;
		border: 1px solid #e0e0e0;
		border-radius: 4px !important;
	}
}
.check-plate-row {
	margin-top: 12px;
}

.unc-limit {
	color: red;
	font-size: 13px;
	font-weight: 500;
	font-weight: 700;
}

.row-aria {
	display: flex;
	justify-content: space-between;
	@include adapt(768px) {
		display: block;
	}
}
.check-plate-row-right {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

.user-projects__options {
	display: flex;
	gap: 4px;
	margin-right: 8px;
}

.check-plate-textarea {
	width: 100%;
	padding: 4px;
	height: 120px !important;
	border: $border;
	border-radius: 4px !important;
	resize: none;
	padding: 8px;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;

	&:hover {
		border: 1px solid $black !important;
	}
	&:hover:disabled {
		border: $border !important;
	}
	&:focus {
		padding: 7px;
		border: 2px solid $main-blue !important;
	}
	@include adapt(768px) {
		width: 100%;
		margin-top: 8px;
		&:first-child {
			margin-top: 0;
		}
	}
}
.row-cell {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
}
.row-cell--flexEnd {
	justify-content: flex-end;
}
.cell {
	width: calc(100% / 5);
	@include adapt(1200px) {
		width: calc(100% / 4);
	}
	@include adapt(968px) {
		width: calc(100% / 3);
	}
	@include adapt(768px) {
		width: calc(100% / 2);
	}
	@include adapt(480px) {
		width: 100%;
	}
}
.cell_wide {
	width: calc(100% / 2);
	padding: 0 6px;
	&.cell_wide-half {
		width: calc(100% / 4);
	}
	@include adapt(1200px) {
		width: calc(100% / 3);
	}
	@include adapt(968px) {
		width: calc(100% / 2);
	}
	@include adapt(768px) {
		width: calc(100% / 2);
	}
	@include adapt(480px) {
		width: 100%;
	}
}
.modal_over {
	display: block;
	color: red;
	background-color: #fff;
	padding: 5px;
	font-size: 12px;
	font-weight: 500;
	font-weight: 700;
	line-height: 11px;
}

.cell-item {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 12px;
	padding: 4px;
	&-label {
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
	}
}
.cell-item-left {
	width: calc(100% - 58px);
}
.cell-item-right {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 70px;
	margin-left: 8px;

	&__inner {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		white-space: nowrap;
		gap: 2px;
		margin-top: 36px;
	}
}
.cell-label {
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 7px;
	color: gray;
}
.author-label {
	display: flex;
	align-items: center;
	font-size: 14px;
	margin-bottom: 12px;
	color: black;
}

.project_carousel_wide {
	width: 75%;
}

.check-plate__calendar {
	border: $border;
	border-radius: 4px !important;
}
.calendar-start {
	border-right: $border !important;
}
.calendar-start,
.calendar-end {
	padding: 0 4px !important;
	.MuiInputBase-input {
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
		color: $black;
	}
}

.load_btn {
	background-image: url('/assets/images/Vector.png');
	@include circle_btn;
	position: relative;
}
.cell-file {
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	margin-left: 4px;
}
.cell__cost-info {
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $table-bg;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	min-height: 32px;
	padding: 8px;
	margin-top: 4px;
	cursor: default;
	span {
		font-size: 12px;
		line-height: 16px;
		color: rgba(0, 0, 0, 0.34);
	}
}
.wrapper-controls {
	margin-top: 16px;
}
.check-plate__uploader {
	display: none;
}

.checkbox-wrapper {
	display: flex;
	align-items: flex-end;
	max-height: 20px;
	margin-bottom: 12px;
}

.errors-list {
	max-height: 500px;
	overflow-y: auto;
}

.errors-item {
	margin-top: 16px;
	&:first-child {
		margin-top: 0;
	}
}
.massErrors__title {
	font-size: 16px;
	display: block;
	font-weight: 700;
	cursor: pointer;
	margin-top: 8px;
	padding: 8px 0;
}
.errors-item__title {
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	white-space: nowrap;
	cursor: pointer;
	.open {
		transform: rotate(-90deg);
	}
}
.errors-item [class*='-icon'].open {
	transform: rotate(0deg);
	transition: all 0.15s ease-in-out;
}
.errors-item__desc,
.errors-item__success {
	display: flex;
	margin-top: 8px;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	&:first-child {
		margin-top: 0;
	}
}
.count-error {
	font-weight: 500;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #ff5c5c;
}

.errors-item__error-shrt-txt {
	margin-left: 13px;
	display: block;
	width: calc(100% - 29px);
}
.errors-item__success-shrt-txt {
	margin-left: 13px;
	display: block;
	width: calc(100% - 29px);
}
.errors__wrapper__btn {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 75px;
	box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
	margin-top: 90px;
	padding: 16px 40px 16px 0;
}
.errors-item-btn {
	min-width: 80px;
}

.note_btn {
	color: $main-blue;
	cursor: pointer;
	border-bottom: 1px solid transparent;
	transition: 0.3s;
	&:hover p {
		display: inline-block;
		transition: 0.3s;
		border-bottom: 1px solid $main-blue;
	}
}

.project_carousel_wrapper {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 8px;
}
.project_carousel {
	width: 50%;
}
.carousel_container {
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	&-disabled {
		background-color: rgba(0, 0, 0, 0.03);
	}
}
.carousel_elem {
	display: flex;
	align-items: center;
	height: 32px;
	.label {
		font-size: 14px;
		line-height: 16px;
		color: $light-grey;
		margin-right: 4px;
		width: 32px;
	}
	.value {
		width: calc(100% - 32px);
		font-size: 14px;
		line-height: 16px;
		input {
			width: 100%;
		}
	}
}
.swiper-button-prev {
	position: absolute;
	background-image: url('/assets/images/svg/swiper-arrow.svg');
	background-repeat: no-repeat;
	width: 10px;
	left: auto;
	right: 60px;
	top: 50%;
	transform: rotate(180deg);
	background-position: center right;
	z-index: 0;
}
.swiper-button-next {
	position: absolute;
	background-image: url('/assets/images/svg/swiper-arrow.svg');
	background-repeat: no-repeat;
	width: 10px;
	top: 50%;
	right: 45px;
	background-position: center right;
	z-index: 0;
}
.swiper-button-prev::after,
.swiper-container-rtl .swiper-button-next::after {
	display: none;
}
.swiper-button-next::after,
.swiper-container-rtl .swiper-button-prev::after {
	display: none;
}
.currency {
	font-size: 12px;
	line-height: 16px;
	color: $light-grey;
}

.check-plate__shrt-txt {
	font-size: 12px;
}
.check-plate__checkbox {
	margin-bottom: 8px;
	input {
		display: none;
		&:checked + label {
			&::before {
				background-color: $main-blue;
				background-image: url('/assets/images/Vector_38.png');
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}
	label {
		font-size: 14px;
		padding-left: 24px;
		position: relative;
		cursor: pointer;
		&::before {
			content: '';
			position: absolute;
			border-radius: 4px;
			border: 1px solid $main-blue;
			width: 16px;
			height: 16px;
			left: 0;
			top: calc(50% - 8px);
		}
	}
}
.check-plate-input {
	display: block;
	width: 100%;
}
.stage-select {
	.MuiInputBase-input {
	  &::placeholder {
		font-size: 14px !important; 
		font-weight: 500 !important;
		color: black !important;
	  }
	}
  }
  