@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';
.range-price {
	margin-top: $margin;
	&__head {
		@include flex-between-center;
		font-size: 12px;
		line-height: 16px;
		color: rgba(0, 0, 0, 0.56);
		margin-bottom: $margin;
	}
	&__title {
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
	}
	&__fields {
		display: flex;
		justify-content: space-between;
		margin-bottom: $margin;

		&-item:first-child {
			margin-right: $margin + 2;
		}
	}

	&__checkbox {
		display: flex;
		align-items: center;
	}
}
.range-price__head-title {
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	color: $black;
}
