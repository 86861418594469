.selected_value {
	cursor: pointer;
	width: 100%;
	text-align: center;
}
.selected_value.disabled {
	cursor: default;
}
.select {
	position: relative;
	border: $border;
	border-radius: 4px;
	padding: 4px;
	height: 32px;
	width: 100%;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.select_list {
	position: absolute;
	background: #ffffff;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
	border-radius: 6px;
	background: #fff;
	display: none;
	width: 100%;
	z-index: 8;
	top: 40px;
	left: 0;
	padding: 0;
	overflow: hidden;
	max-height: 350px;
	height: auto;

	&.multy {
		.select_list_elem {
			padding-left: 33px;
		}
		.checked {
			&::before {
				content: '';
				width: 12px;
				height: 8px;
				position: absolute;
				top: calc(50% - 4px);
				left: 12px;
				background-image: url('/assets/images/Vector_83.png');
			}
		}

		.checked:hover {
			&::before {
				background-image: url('/assets/images/arrow_w.png');
			}
		}
	}

	&_elem {
		padding: 4px 10px;
		cursor: pointer;
		min-height: 32px;
		font-size: 14px;
		display: flex;
		align-items: center;
		transition: none;
		position: relative;
		&:hover {
			background: $blue-dark;
			color: #fff;
		}
	}
}

.opened_select {
	display: block;
}

.region_input_filter{
	width: 100%;
	text-align: center;
}

.no_results_message_filter::placeholder{
	color: black;
}

.no_results_message_filter{
	padding: 20px;
}

