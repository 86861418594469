@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';
.user-projects__header {
	min-height: 32px;
	margin-bottom: 16px;
	display: flex;
	align-items: center;
}
.user-projects__list {
	max-height: calc(100vh - 115px);
	overflow-y: scroll;
}
.user-projects__nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	.add_btn {
		padding: 4px 8px;
		border-radius: 8px;
		svg {
			width: 20px;
			height: 20px;
			margin-right: 4px;
			stroke: $blue-dark;
		}
		&:active {
			svg {
				stroke: $main-blue;
			}
		}
		display: inline;
		margin-right: 0.4em;
	}
	.user-projects__options {
		width: 100%;
		display: flex;
		justify-content: space-between;
		// justify-content: flex-start;
		margin-bottom: 16px;
		gap: 8px;
	}
	.user-projects__options-item {
		font-size: 18px;
		&:first-child {
			margin-left: 0;
		}
	}
	@include adapt(480px) {
		flex-direction: column;
	}
}
.user-projects__options {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	.MuiIconButton-root {
		padding: 6px;
	}
}
.user-projects__options-shrt-txt {
	font-weight: normal;
	font-size: 16px;
	line-height: 22px;
	color: $grey;
}
.user-projects__options-count,
.user-projects__options-sum {
	font-weight: 700;
	font-size: 16px;
	line-height: 22px;
}

.content {
	position: relative;
	width: 10px;
	#content__help_outline_img {
		width: 15px;
		height: 15px;
		padding-left: 3px;
		margin: 0 0 10px;
		position: absolute;
		bottom: -10px;
		left: 0;
	}
}
