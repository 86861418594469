@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.t7title {
	text-align: center;
	font-size: 14px;
	font-weight: 500;
	font-weight: 700;
	line-height: 120%;
	margin-bottom: 16px;
}

.t7text {
	font-size: 13px;
	line-height: 14px;
}

.scrollarea1 {
	overflow-y: scroll;
}
.divTable {
	display: table;
	border-collapse: collapse;
	width: 100%;
	table-layout: auto;
	font-size: 12px;
	line-height: 120%;
	border: 2px solid white;

	& th,
	td {
		font-size: 14px;
		font-weight: 400;
		background-color: #fff;
		color: #000;

		padding: 7px 12px;
		border: $border;
	}

	& th {
		padding: 16px 12px;
	}

	.title-total {
		font-weight: 700;
		font-size: 16px;
	}

	.theadName {
		display: block;
	}

	td {
		text-align: center;
	}

	.dataRow {
		td:first-child,
		td + td {
			border-top: none;
			border-bottom: none;
		}
	}
	tbody .dataRow:nth-child(2) td {
		padding-top: 12px;
	}
}

.divTable.unnormalizedCostsForm20 {
	& th,
	td {
		border: $border;
	}

	.noBorder td {
		padding-top: 20px;
		border: 1px solid transparent;
	}

	.divTableCell,
	.divTableCellCenter,
	.divTableHead {
		padding: 7px;
	}
}

.divTableRow {
	display: table-row;
}
.divTableHeading {
	display: table-header-group;
}

.divTableCell {
	display: table-cell;
	font-size: 12px;
	line-height: 120%;
	padding: 7px 12px;
}
.divTableCellCenter,
.divTableHead {
	display: table-cell;

	padding: 7px 12px;
	font-size: 12px;
	line-height: 120%;
	text-align: center;
	vertical-align: middle;
}
.divTableHead {
	font-weight: 500;
	font-weight: 700;
}
.divTableHeading {
	display: table-header-group;
	font-weight: bold;
}
.divTableFoot {
	display: table-footer-group;
	font-weight: bold;
}
.divTableBody {
	display: table-row-group;
}

.unormcsts_input {
	border: $border;
	padding: 4px 8px;
	text-align: center;
}

.unormcsts_input-form20 {
	width: 100%;
	height: 37px;
	border: 1px solid #000000;
	border-radius: 10px;
	padding: 4px 8px;
	font-size: 14px;
	text-align: center;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	&[type='number'] {
		-moz-appearance: textfield;
	}
}

.unnormalizedCostsOld {
	.divTableHeading {
		border: $border;
	}
	.divTableCell {
		border: $border;
	}
	.divTableCellCenter,
	.divTableHead {
		border: $border;
	}
	.divTableHead {
		border: $border;
	}
}
.unnormalizedCostsOld_wrapper {
	.unnormalized__footer {
		padding: 16px 0;
	}
}

.unnormalized__footer {
	width: 100%;
	position: sticky;
	bottom: 0;
	background: white;
	padding: 16px 0 0;
	border-top: 1px solid #eee;
}

.title-nodata {
	color: $error;
	text-align: center;
	padding: 16px;
}

.instructions-wrap {
	.instructions {
		&-text {
			margin-top: 16px;
			line-height: 120%;
		}
		&-list {
			margin: 8px 0 8px 8px;
		}
		&-item {
			margin-left: 4px;
			margin-top: 8px;
		}
	}
}
