.progressContainer {
    position: relative;
    width: auto;
    // height: 30px;
    // margin: 70px auto;
  }
  
  .progressCircle svg {
    width: auto;
    height: auto;
  }
  
  .progressCircle-bg {
    fill: none;
    stroke: #e6e6e6;
  }
  
  .progressCircle-fg {
    fill: none;
    stroke: #1baaf0 !important;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  
  .progressPercentage {
    position: absolute;
    top: 44%;
    left: 43%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    font-weight: bold;
    color: #1baaf0;
    line-height: 1;
  }

  .big-form_load{
    font-size: 40px;
    top: 48%;
    left: 50%;
  }
  