@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';
.user-projects__header {
	min-height: 32px;
	padding: 8px 0;
	display: flex;
	align-items: center;
}
.user-projects__nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	.add_btn {
		svg {
			width: 20px;
			height: 20px;
			margin-right: 4px;
			stroke: $blue-dark;
		}
		&:active {
			svg {
				stroke: $main-blue;
			}
		}
		display: inline;
		margin-right: 0.4em;
	}
	.import_magazine {
		svg {
			width: 20px;
			height: 20px;
			margin-right: 4px;
			stroke: $blue-dark;
		}
		&:active {
			svg {
				stroke: $main-blue;
			}
		}
		display: inline;
		width: 70px !important;
		margin-right: 0.4em;
		min-width: fit-content;
		border-radius: 8px;
	}
	.user-projects__options-item {
		font-size: 18px;
		margin-left: 16px;
		&:first-child {
			margin-left: 0;
		}
	}
	@include adapt(480px) {
		flex-direction: column;
	}
}
.user-projects__options {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	.MuiIconButton-root {
		padding: 6px;
	}
}
.user-projects__options-shrt-txt {
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: $grey;
}
