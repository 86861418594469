.purchase_status {
	width: 144px;
	font-size: 13px;
	line-height: 16px;
	text-align: center;
	border: 1px solid #3f4f5e;
	padding: 8px 12px;
	margin-top: 8px;
	border-radius: 16px;
	cursor: pointer;
}

.purchase_status_changed {
	width: 144px;
	font-size: 13px;
	line-height: 16px;
	text-align: center;
	border: 2px solid #1baaf0;
	padding: 8px 12px;
	margin-top: 8px;
	border-radius: 16px;
	cursor: pointer;
}

.purchase__list {
	max-height: calc(100vh - 115px);
	overflow-y: scroll;
}

.stages_history {
    width: 200px;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    /* border: 1px solid #3f4f5e; */
    margin-top: -30px;
}

.stage {
    font-size: 12px;
    line-height: 18px;
    padding-left: 5px;
    border-left: 1px solid #3f4f5e;
}

.okved__list {
	padding: 0;
	margin: 7px 0 0 0;
	list-style: none;
	font-size: 12px;
}

.linkedProjectListTitle {
	display: block;
	padding: 0;
	margin: 20px 0 0 0;
	font-size: 15px;
}

.linkedProjectList {
	padding: 0;
	margin: 12px 0 0 0;
	list-style: none;
	font-size: 12px;
}

// @media (max-width: 1700px) {
//   .purchases-container {
//     &__filter.MuiGrid-grid-xs-2 {
//       max-width: 100% * 0.2;
//       flex-basis: 100% * 0.2;
//     }

//     &__list.MuiGrid-grid-xs-8 {
//       padding-right: 5%;
//       flex-basis: 100% * 0.8;
//       max-width: 100% * 0.8;
//     }
//   }
// }

// @media (max-width: 1000px) {
// 	.purchases-container {
// 		&__filter.MuiGrid-grid-xs-2 {
// 			min-width: 260px;
// 		}

// 		&__list.MuiGrid-grid-xs-8 {
// 			flex-basis: 70%;
// 			max-width: 70%;
// 		}
// 	}
// }

// @media (max-width: 1380px) {
// 	.purchases-container {
// 		margin-left: 0 !important;

// 		&.MuiGrid-justify-xs-center {
// 			justify-content: start;
// 		}

// 		&__sort {
// 			flex-grow: 0;
// 			max-width: 100%;
// 			flex-basis: auto;
// 			padding-bottom: 20px;
// 		}

// 		&__sum.MuiGrid-grid-xs-5 {
// 			flex-grow: 0;
// 			max-width: 100%;
// 			flex-basis: 100%;
// 		}
// 	}
// }

// .purchases-container {
// 	margin-left: 7%;
// }
