@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.card-inside-container {
	border-radius: 24px;
	border: $border;
}
.detail-common-projects {
	.card-full__item {
		display: flex;
		@include adapt(768px) {
			flex-wrap: wrap;
		}
	}
}
.detail-purchases {
	.card-full__item {
		@include adapt(768px) {
			display: block;
		}
	}
}
.card-full-wr {
	display: flex;
	width: 100%;
	@include adapt(768px) {
		flex-wrap: wrap;
	}
}
.card-full__header {
	@include flex-between-center;
}
.card-full__header-left {
	display: flex;
	align-items: center;
}
.card-full__header {
	&-icons {
		margin-left: 12px;
		display: flex;
		align-items: center;
		gap: 8px;
	}
	&-lock {
		width: 24px;
		height: 24px;
		& .lock-icon,
		& .unlock-icon {
			cursor: auto;
			background-color: #607084;
			width: 100%;
			height: 100%;
		}
	}
}

.card-full__actions {
	display: flex;
	align-items: center;
}
.card-full__actions-item {
	margin-left: 15px;
	font-size: 14px;
	line-height: 16px;
	@include adapt(480px) {
		margin-left: 5px;
	}
}
.card-full__code {
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	position: relative;
	.code {
		margin-left: 4px;
	}
}
.card-full_detailed-btn {
	font-size: 13px;
	padding: 0;
	cursor: pointer;
}
.card-inside__head {
	display: flex;
	flex-wrap: wrap;
	grid-column-gap: 24px;
	column-gap: 24px;
	grid-row-gap: 8px;
	row-gap: 8px;
	background-color: rgba(0, 0, 0, 0.03);
	padding: 16px;
	@include adapt(1200px) {
		column-gap: 12px;
	}
	@include adapt(768px) {
		grid-row-gap: 0;
		column-gap: 0;
	}
}
.card-inside-title {
	width: 100%;
	padding: 16px 0 0 16px;
	background-color: rgba(0, 0, 0, 0.03);
}
.card-inside__detailed-expand {
	padding: 16px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 16px;
	background-color: rgba(0, 0, 0, 0.03);
	@include adapt(1200px) {
		grid-template-columns: repeat(6, 1fr);
	}
	@include adapt(992px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@include adapt(768px) {
		grid-template-columns: 1fr;
	}

	.grid-item {
		grid-column: span 1;
		@include adapt(1200px) {
			grid-column: span 2;
		}
		@include adapt(992px) {
			grid-column: span 1;
		}
	}
	.grid-item-md {
		grid-column: span 2;
		@include adapt(1200px) {
			grid-column: span 3;
		}
		@include adapt(992px) {
			grid-column: span 1;
		}
	}
	.grid-item-md-lg {
		grid-column: span 2;
		@include adapt(1200px) {
			grid-column: span 4;
		}
		@include adapt(992px) {
			grid-column: span 1;
		}
	}
	.item-sm {
		width: calc(100% / 4 - 18px);
		@include adapt(1200px) {
			width: calc(100% / 3 - 8px);
		}
		@include adapt(992px) {
			width: calc(100% / 2 - 6px);
		}
		@include adapt(768px) {
			width: 100%;
		}
	}
}
.card-inside__detailed-expand-info__item,
.card-container__detailed-info__item {
	&.item-s {
		width: calc(100% / 5 - 20px);
		@include adapt(1200px) {
			width: calc(100% / 4 - 9px);
		}
		@include adapt(992px) {
			width: calc(100% / 2 - 6px);
		}
		@include adapt(768px) {
			width: 100%;
		}
	}
	&.item-sm {
		width: calc(100% / 4 - 18px);
		@include adapt(1200px) {
			width: calc(100% / 3 - 8px);
		}
		@include adapt(992px) {
			width: calc(100% / 2 - 6px);
		}
		@include adapt(768px) {
			width: 100%;
		}
	}
	&.item-md {
		width: calc(100% / 2 - 12px);
		@include adapt(1200px) {
			width: calc(100% / 2 - 6px);
		}

		@include adapt(768px) {
			width: 100%;
		}
	}
	&.item-md-auto {
		min-width: calc(100% / 2 - 12px);
		@include adapt(1200px) {
			min-width: calc(100% / 2 - 6px);
		}
		@include adapt(768px) {
			width: 100%;
		}
	}
	&.item-lg {
		width: 100%;
	}
}
.card-inside__detailed-expand-title {
	font-size: 12px;
	line-height: 16px;
	color: rgba(0, 0, 0, 0.87);
	height: 32px;
	display: flex;
	align-items: flex-end;
}
.card-inside__detailed-expand-value {
	font-size: 14px;
	line-height: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: $border;
	border-radius: 4px;
	min-height: 32px;
	padding: 8px;
	cursor: default;
	background-color: white;
}
