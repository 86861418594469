@import '@root-gipro/styles/config/variables.scss';
@import '@root-gipro/styles/config/mixins.scss';

.avatar {
	.MuiAvatar-root-128 {
		width: 32px;
		height: 32px;
	}

	cursor: pointer;
	@include adapt(480px) {
		margin-left: 5px;
	}
}

.navLink {
	color: inherit;
	text-decoration: none;
}

.user {
	display: flex;
	align-items: center;
	gap: 8px;
}
.username {
	font-size: 14px;
	color: #fff;
}
